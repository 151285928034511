* {
  margin: 0;
  padding: 0;
}
html {
  font-size: 10px;
  font-family: 'Roboto', sans-serif;
}
html,
body {
  height: 100%;
  width: 100%;
}
#root,
.app {
  height: 100%;
  width: 100%;
}
