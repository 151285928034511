$white: #ffffff;
$light-white: #a1abb6;
$green: #8acd3e;
$light-green: #98ca55;
$green-hover: #5d8a29;
$green-primary: #18a57e;
$green-badge-text: #49a181;
$green-badge-background: #daece7;
$light-green: #f5faee;
$dark-background: #0c2e4a;
$light-navy: #253d55;
$light-gray: #f9fafa;
$text-color: #2a405a;
$border-color: #f0f0f0;
$light-gray-text: #d4d9de;
$dark-gray-text: #667a84;
$input-background: #fafafb;
$red: #ff7474;
$header-text-color: #a4acbf;
$task-border-color: #5a6d80;
