@font-face {
  font-family: 'ProximaNova Regular';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova-Regular.woff') format("woff");
  src: url('../fonts/ProximaNova-Regular.ttf')  format('truetype');
}

@font-face {
  font-family: 'ProximaNova Semibold';
  src: url('../fonts/ProximaNova-Semibold.eot');
  src: url('../fonts/ProximaNova-Semibold.woff') format("woff");
  src: url('../fonts/ProximaNova-Semibold.ttf')  format('truetype');
}

@font-face {
  font-family: 'Mont Heavy';
  src: url('../fonts/Mont-Heavy.eot');
  src: url('../fonts/Mont-Heavy.woff2') format("woff2");
  src: url('../fonts/Mont-Heavy.woff') format("woff");
  src: url('../fonts/Mont-Heavy.ttf')  format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue Regular';
  src: url('../fonts/HelveticaNeueCyr-Roman.eot');
  src: url('../fonts/HelveticaNeueCyr-Roman.woff2') format("woff2");
  src: url('../fonts/HelveticaNeueCyr-Roman.woff') format("woff");
  src: url('../fonts/HelveticaNeueCyr-Roman.ttf')  format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue Medium';
  src: url('../fonts/HelveticaNeueCyr-Medium.eot');
  src: url('../fonts/HelveticaNeueCyr-Medium.woff2') format("woff2");
  src: url('../fonts/HelveticaNeueCyr-Medium.woff') format("woff");
  src: url('../fonts/HelveticaNeueCyr-Medium.ttf')  format('truetype');
}

@font-face {
  font-family: 'ProximaNova Bold';
  src: url('../fonts/ProximaNova-Bold.eot');
  src: url('../fonts/ProximaNova-Bold.woff') format("woff");
  src: url('../fonts/ProximaNova-Bold.ttf')  format('truetype');
}

@font-face {
  font-family: 'ProximaNova Light';
  src: url('../fonts/ProximaNova-Light.eot');
  src: url('../fonts/ProximaNova-Light.woff') format("woff");
  src: url('../fonts/ProximaNova-Light.ttf')  format('truetype');
}