.credentials-wrapper {
  display: flex;
  flex-direction: column; 
  margin-left: 30px;
  width: 35%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 6px 4px 14px #061b2f38;

  .credential {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin-bottom: 20px;

    &.mb-0 {
      margin-bottom: 0px;
    }

    .inner {
      display: flex;
      flex-direction: column;

      .label {
        color: #667A84;
        font-size: 11px !important;
        line-height: 17px !important;
      }

      .val {
        margin-top: 10px;
      }
    }

    .icon {
      width: 1.75em !important;
      height: 1.75em !important;
      margin-left: 20px;
      margin-top: 10px;
    }
  }
}