$weekend-background: rgba(254, 106, 106, 0.05);
$gray-border: #f0f1f2;
$black: #000000;
$today-background: #f8fbf3;

.react-calendar-timeline * {
  box-sizing: border-box;
}

.react-calendar-timeline .rct-outer {
  display: block;
  white-space: nowrap;
}

.rct-outer {
  border-radius: 0 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.react-calendar-timeline .rct-scroll {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.react-calendar-timeline .rct-scroll::-webkit-scrollbar {
  display: none !important;
}

.react-calendar-timeline .rct-item:hover {
  z-index: 88;
}

.react-calendar-timeline .rct-item .rct-item-content {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
}

.react-calendar-timeline .rct-sidebar {
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid $gray-border;
}
.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
  border-right: 0;
  border-left: 1px solid $gray-border;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid $gray-border;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row:last-child {
  border-bottom: 1px solid transparent;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  position: absolute;
  border-left: 1px solid $gray-border;
  z-index: 30;
}
.today {
  background-color: $today-background;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-left-width: 2px;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: #fff8f8;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.weekend-overlay {
  z-index: 90;
  background: #fff8f8;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.working-day {
  z-index: 29;
}

.react-calendar-timeline .rct-horizontal-lines {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid $gray-border;
  box-sizing: border-box;
  z-index: 40;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even:last-child,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd:last-child {
  border-bottom: 1px solid transparent;
}

.react-calendar-timeline .rct-cursor-line {
  position: absolute;
  width: 2px;
  background: #2196f3;
  z-index: 51;
}

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: 1px solid $gray-border;
  cursor: pointer;
  font-size: 14px;
  background-color: #f0f0f0;
  border-left: 2px solid $gray-border;
  padding: 10px !important;
  text-align: center !important;
  font-family: 'HelveticaNeue Regular', sans-serif;
}

.react-calendar-timeline .rct-dateHeader-primary {
  background-color: initial;
  border-left: 1px solid $gray-border;
  border-right: 1px solid $gray-border;
  color: $black;
}

.react-calendar-timeline .rct-header-root {
  border-bottom: 1px solid #f0f1f2;
}

.react-calendar-timeline .rct-calendar-header {
}

.row-root {
}
